import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
// import FacebookIcon from "../assets/facebook";
import Burger from "../assets/burger";
import ArrowNext from "../assets/arrowNext";
import ArrowPrev from "../assets/arrowPrev";
import Logo from "../assets/logo";
import { Dropdown } from "bootstrap";
import "./Header.scss";

const Header = () => {
  useEffect(() => {
    const projectDropdownItems = document.querySelectorAll(
      ".project-dropdown-toggle"
    );
    projectDropdownItems.forEach((item) => {
      new Dropdown(item);
    });
  }, []);

  const [isMenuShown, setIsMenuShown] = useState(false);

  const closeMenu = () => {
    setCurrentMenulevel(1);
    setIsMenuShown(false);
  };

  const toggleMenuOpen = () => {
    setCurrentMenulevel(1);
    setIsMenuShown(!isMenuShown);
  };

  useEffect(() => {
    if (isMenuShown) {
      document.body.classList.add("show-menu");
    } else {
      document.body.classList.remove("show-menu");
    }
  }, [isMenuShown]);

  const [currentMenulevel, setCurrentMenulevel] = useState(1);
  const goToNextMenuLevel = () => {
    setCurrentMenulevel(currentMenulevel + 1);
  };
  const goToPrevMenuLevel = () => {
    setCurrentMenulevel(currentMenulevel - 1);
  };

  const ProjectLinks = () => {
    return (
      <>
        <li>
          <NavLink
            to="/ever-art"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " dropdown-link nav-link"
            }
            onClick={closeMenu}
          >
            Ever Given
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/crevice"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " dropdown-link nav-link"
            }
            onClick={closeMenu}
          >
            Crevice
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/into-the-dark"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " dropdown-link nav-link"
            }
            onClick={closeMenu}
          >
            Into The Dark
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/everything-is-the-same-but-everything-is-different"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " dropdown-link nav-link"
            }
            onClick={closeMenu}
          >
            Everything is the same but everything is different
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/blurred-identities"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " dropdown-link nav-link"
            }
            onClick={closeMenu}
          >
            Blurred Identities
          </NavLink>
        </li>
      </>
    );
  };

  const HeaderLinks = () => {
    return (
      <>
        <li className="list-unstyled">
          <NavLink
            to="/exhibitions"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " nav-link"
            }
            onClick={closeMenu}
          >
            Exhibitions
          </NavLink>
        </li>
        <li className="list-unstyled">
          <NavLink
            to="/bibliography"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " nav-link"
            }
            onClick={closeMenu}
          >
            Bibliography
          </NavLink>
        </li>
        <li className="list-unstyled">
          <NavLink
            to="/about"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " nav-link"
            }
            onClick={closeMenu}
          >
            About
          </NavLink>
        </li>
        <li className="list-unstyled">
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              (isActive ? "active" : "") + " nav-link  m-0"
            }
            onClick={closeMenu}
          >
            Contact
          </NavLink>
        </li>
      </>
    );
  };

  return (
    <header>
      <div className="container container-medium">
        <div className="row header-row gx-0 justify-content-between  align-items-center">
          <div className="col-auto">
            <div className="main-logo">
              <Link to="/" className="d-inline-block lh-0 main-logo-btn" onClick={closeMenu}>
                <Logo />
              </Link>
            </div>
          </div>

          <div className="col-auto d-none d-md-block">
            <ul className="header-nav m-0 p-0 d-flex justify-content-end font-size-11 font-light-alternative align-items-center text-uppercase h-100">
              <li className="list-unstyled position-relative">
                <div className="dropdown">
                  <button
                    className="nav-link text-uppercase dropdown-toggle dropdown-project-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    Projects
                  </button>
                  <div className="dropdown-menu header-nav-dropdown">
                    <ul className="list-unstyled">
                      <ProjectLinks />
                    </ul>
                  </div>
                </div>
              </li>
              <HeaderLinks />
            </ul>
          </div>

          <div className="col-auto d-md-none">
            <button className="burger p-0" onClick={toggleMenuOpen}>
              <Burger />
            </button>
          </div>
        </div>

        <div className="responsive-menu pt-4 d-md-none">
          <div className="container container-medium">
            <ul className="list-unstyled header-nav m-0 p-0 font-size-14 ls-half font-light-alternative text-uppercase">
              {currentMenulevel === 1 && (
                <li className="position-relative responsive-menu-first-level">
                  <button
                    href="!#"
                    className="project-btn"
                    id="project-btn"
                    onClick={goToNextMenuLevel}
                  >
                    Projects
                    <ArrowNext />
                  </button>
                  <ul className="list-unstyled">
                    <HeaderLinks />
                  </ul>
                </li>
              )}
              {currentMenulevel === 2 && (
                <li className="position-relative responsive-menu-second-level">
                  <ul className="list-unstyled">
                    <li className="mb-3">
                      <button
                        href="!#"
                        className="go-back-btn"
                        onClick={goToPrevMenuLevel}
                      >
                        <ArrowPrev />
                        Back To Menu
                      </button>
                    </li>
                    <ProjectLinks />
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
