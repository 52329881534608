import React from "react";
import ProjectTemplate from "../ProjectTemplate";
import "../Projects.scss";

const ProjectTitle = () => {
  return <h2 className="mb-3 mb-md-4">Ever Given</h2>;
};

const ProjectDescription = () => {
  return (
    <div className="project-description">
      <p>
        The Series "Ever Given" deals with ports from the North to the South,
        presenting them as a place full of both literal and metaphorical
        contrasts. After all, "transportation" is their quintessential function.
        The concept of the port is explored photographically as an integral part
        of a consumption cycle and simultaneously as a crossroads with its own
        laws and rules, with the ability not only to separate but also to unite
        different localities and cultures. While the oceans may have been a
        platform of dominance and death through naval battles, colonization, and
        violent displacement, the sea, the liquid element by its nature, can
        "wash away" and "rupture" the indissoluble borders we have set among
        ourselves as humanity.
      </p>
    </div>
  );
};

const projectConfig = [
  {
    images: [
      { src: "../images/projects/ever-art/1.jpg" },
      { src: "../images/projects/ever-art/2.jpg" },
      { src: "../images/projects/ever-art/3.jpg" },
      { src: "../images/projects/ever-art/4.jpg" },
      { src: "../images/projects/ever-art/5.jpg" },
      { src: "../images/projects/ever-art/6.jpg" },
      { src: "../images/projects/ever-art/7.jpg" },
    ],
    slidesperview: 1,
  },
];

const EverArt = (props) => {
  return (
    <ProjectTemplate
      projectConfig={projectConfig}
      title={<ProjectTitle />}
      description={<ProjectDescription />}
    />
  );
};

export default EverArt;
