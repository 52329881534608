import React from "react";

const About = () => {
  return (
    <div className="container container-medium">
      <h1 className="mb-4">Bio</h1>
      <div className="row align-items-center">
        <div className="col-md-6">
          <p>Efi Haliori is an Athens-based artist. She has participated in several exhibitions at galleries
            and art centres internationally, including the Shanghai Museum in China, The National Art
            Centre in Tokyo, Japan, the Salon d’Automne in Paris, France, and the Municipal Gallery of
            Piraeus. Her work explores the ephemeral nature of both human life and the planet from
            her own geographical vantage point and personal experience. Simplicity, minimalism, and
            abstraction are the qualities that drive her creative practice. Through persistent
            observation, she conveys a distilled experience of her subject matter, rather than mere
            aesthetic documentation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
