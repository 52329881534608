import React from "react";
//import FullPageImagePopup from "./FullPageImagePopup";

import "./Home.scss";

const Home = () => {
  return (
    <>
      <div className="home-wrapper">
        <div className="container container-medium">
          <div className="row gx-3 gx-lg-4">
            <div className="col-12 pb-3 pb-lg-4">
              <div className="d-flex h-100">
                  <img
                  className="img-fluid d-sm-block d-none"
                  src="../images/ever-given-invitation.jpg"
                  alt="Ever Given Exhibition Invitation"  />
                  <img
                  className="img-fluid d-sm-none d-block"
                  src="../images/ever-given-invitation-mobile.jpg"
                  alt="Ever Given Exhibition Invitation"  />
              </div>
            </div>
           {/*
            <div className="col-6 pb-3 pb-lg-4">
              <div className="d-flex h-100">
                <img src="../images/projects/crevice/main.jpg" className="img-fluid object-fit-contain" alt="Crevice" />
              </div>
            </div>
            <div className="col-6 pb-3 pb-lg-4">
              <div className="d-flex h-100">
                <img src="../images/projects/everything-is-the-same-but-everything-is-different/19.jpg" className="img-fluid object-fit-contain" alt="Everything is the same but everything is different" />
              </div>
            </div>
            <div className="col-6 pb-3 pb-lg-4">
              <div className="d-flex h-100">
                <img src="../images/projects/into-the-dark/2.jpg" className="img-fluid object-fit-contain" alt="into the dark" />
              </div>
            </div>
            <div className="col-6 pb-3 pb-lg-4">
              <div className="d-flex h-100">
                <img src="../images/projects/into-the-dark/9.jpg" className="img-fluid object-fit-contain" alt="into the dark" />
              </div>
            </div>
          	 */}
          </div>
        </div>
         {/*<FullPageImagePopup /> */}
      </div>
    </>
  );
};


export default Home;
